@charset "UTF-8";
@font-face {
  font-family: "ryumin-l";
  src: url("../asset/font/ryumin-l.woff2") format("woff2"), url("../asset/font/ryumin-l.woff") format("woff"), url("../asset/font/ryumin-l.otf") format("opentype");
}

@font-face {
  font-family: "ryumin-m";
  src: url("../asset/font/ryumin-m.woff2") format("woff2"), url("../asset/font/ryumin-m.woff") format("woff"), url("../asset/font/ryumin-m.otf") format("opentype");
}

.flow-ttl {
  font-size: 1.6rem;
  font-family: "ryumin-l", serif;
  font-weight: 500;
  font-style: normal;
  line-height: 1.53125;
}

.mv-ttl, .flow-ttl span, .merit-btn, .index-ttl {
  font-family: "Crimson Text", "Noto Serif JP", serif;
  font-style: normal;
}

.mv {
  height: 600px;
  position: relative;
  width: 1366px;
  margin: auto;
  padding-left: 100px;
  overflow: hidden;
}

@media (max-width: 767px) {
  .mv {
    height: 300px;
    width: 100%;
    padding-left: 0;
  }
}

.mv-list,
.top-mv-img,
.slick-list {
  width: 100%;
  height: 100%;
}

.top-mv-img {
  position: relative;
}

.top-mv-img::before {
  background: rgba(13, 183, 214, 0.4);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: "";
  width: 100%;
  height: 100%;
}

.top-mv-img img {
  object-fit: cover;
  font-family: "object-fit:cover";
  height: 100%;
}

.mv-box {
  position: absolute;
  bottom: 100px;
  left: calc(50% + 80px);
  transform: translate(-50%, 50%);
  color: #fff;
  z-index: 1;
  width: 1266px;
}

.mv-box._sec {
  bottom: auto;
  top: 100px;
  transform: translate(-50%, -50%);
}

@media (max-width: 767px) {
  .mv-box {
    bottom: 50px;
    left: calc(50% + 20px);
    width: 100%;
  }
  .mv-box._sec {
    top: 50px;
  }
}

.mv-ttl {
  font-size: 6.4rem;
  line-height: 1;
}

@media (max-width: 767px) {
  .mv-ttl {
    font-size: 3.4rem;
  }
}

.mv-txt {
  font-size: 2.4rem;
  margin-top: 4px;
}

@media (max-width: 767px) {
  .mv-txt {
    font-size: 1.6rem;
  }
}

.slick-track {
  height: 100%;
}

.top {
  padding: 150px 0;
  position: relative;
}

@media (max-width: 767px) {
  .top {
    padding: 72px 0;
  }
}

.top-flex {
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

@media (max-width: 767px) {
  .top-flex {
    flex-direction: column;
  }
}

.top-img {
  height: 460px;
  float: left;
  width: 800px;
  position: absolute;
  left: -100px;
  top: -100px;
  overflow: hidden;
}

.top-img::before {
  height: 100%;
  width: 100%;
  background: rgba(13, 183, 214, 0.4);
  content: "";
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.top-img img {
  object-fit: cover;
  font-family: "object-fit:cover";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: hidden;
  transition: visibility 0ms 450ms;
}

@media (max-width: 767px) {
  .top-img img {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
  }
}

@media (max-width: 767px) {
  .top-img {
    height: auto;
    width: 100%;
    left: -24px;
    top: 50px;
    position: relative;
  }
}

.top-img::after {
  background: #0db7d6;
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(0, 100%);
}

.top-box {
  background: #282828;
  width: 500px;
  color: transparent;
  padding: 100px;
  position: relative;
  z-index: 1;
}

@media (max-width: 767px) {
  .top-box {
    width: 100%;
    padding: 40px;
  }
}

.top-ttl {
  font-size: 2.4rem;
  padding-bottom: 0.25em;
  position: relative;
  overflow: hidden;
  transition: color 0ms 450ms;
}

@media (max-width: 767px) {
  .top-ttl {
    font-size: 2rem;
  }
}

.top-txt {
  margin-top: 0.5em;
  position: relative;
  overflow: hidden;
  transition: color 0ms 450ms;
}

.top-ttl::after,
.top-txt::after {
  background: #fff;
  bottom: 0;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(0, 100%);
}

.top-ttl.is-active {
  border-bottom: 1px solid #fff;
}

.top-ttl.is-active,
.top-txt.is-active {
  color: #fff;
}

.top-ttl.is-active::after,
.top-txt.is-active::after {
  animation: mask-bg 1.2s cubic-bezier(0.8, 0, 0.17, 1);
}

.top-img.is-active img {
  visibility: visible;
  opacity: 1;
}

.top-img.is-active::after {
  animation: mask-bg 1.2s cubic-bezier(0.8, 0, 0.17, 1);
}

@keyframes mask-bg {
  0% {
    transform: translate(-100%, 0%);
  }
  40%,
  60% {
    transform: translate(0, 0%);
  }
  100% {
    transform: translate(100%, 0%);
  }
}

/* **************************************


     link


***************************************/
.link-list {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 767px) {
  .link-list {
    flex-direction: column;
  }
}

.link-item {
  width: 300px;
  height: 300px;
  position: relative;
  text-align: center;
}

.link-item + li {
  margin-left: 40px;
}

@media (max-width: 767px) {
  .link-item + li {
    margin: 40px auto 0;
  }
}

.arrow {
  width: 30px;
  height: 30px;
  border: 1px solid #fff;
  position: relative;
  margin: 8px auto 0;
  transition: 0.2s;
}

.arrow:after {
  position: absolute;
  right: 15px;
  width: 10px;
  height: 10px;
  top: 50%;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  transform: rotate(45deg) translateY(-50%);
  content: "";
}

.consul-img {
  position: relative;
  transition: 0.2s;
  display: block;
}

.consul-img:before {
  content: "";
  background: rgba(40, 40, 40, 0.8);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  transition: 0.2s;
}

.consul-img img {
  object-fit: cover;
  font-family: "object-fit:cover";
}

.consul-img:hover:before {
  background: rgba(40, 40, 40, 0.4);
}

.consul-img:hover .arrow {
  background: #fff;
}

.consul-img:hover .arrow:after {
  border-top: 1px solid #282828;
  border-right: 1px solid #282828;
}

.img-txt {
  color: #fff;
  font-size: 4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}

.img-txt span {
  font-size: 60%;
  display: block;
}

@media (max-width: 767px) {
  .img-txt {
    font-size: 3rem;
  }
}

/* **************************************


     adv


***************************************/
.adv {
  position: relative;
  padding: 100px 0;
  margin-top: 150px;
}

.adv:before, .adv::after {
  position: absolute;
  height: 450px;
  width: 100%;
  content: "";
  top: 0;
}

@media (max-width: 767px) {
  .adv:before, .adv::after {
    height: 300px;
  }
}

.adv:before {
  background-image: url("../images/index/bg04.jpg");
  background-size: cover;
}

.adv:after {
  background: rgba(40, 40, 40, 0.8);
}

@media (max-width: 767px) {
  .adv {
    padding: 60px 0;
  }
}

.adv-container {
  position: relative;
  z-index: 1;
}

.adv-contents {
  box-shadow: 0px 0px 32px #0000004d;
  background: #fff;
  width: 1000px;
  margin: auto;
}

@media (max-width: 767px) {
  .adv-contents {
    width: 100%;
  }
}

.adv-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 32px 72px;
  margin-top: 24px;
}

@media (max-width: 767px) {
  .adv-list {
    padding: 24px 40px;
  }
}

.adv-item {
  width: calc((100% / 3) - 20px);
}

@media (max-width: 767px) {
  .adv-item {
    width: 100%;
    margin-top: 24px;
  }
}

.adv-item-img {
  margin: auto;
  text-align: center;
}

.adv-item-img img {
  margin: auto;
}

.adv-item-ttl {
  text-align: center;
  margin-top: 32px;
  font-size: 2rem;
}

.adv-item-txt {
  font-size: 1.4rem;
  margin-top: 24px;
}

.adv-box {
  padding: 48px 72px;
  background: #282828;
  color: #fff;
  font-size: 1.8rem;
}

@media (max-width: 767px) {
  .adv-box {
    padding: 24px 40px;
    font-size: 1.6rem;
  }
}

/* **************************************


     .flow


***************************************/
.flow {
  padding: 100px 0;
}

@media (max-width: 767px) {
  .flow {
    padding: 60px 0;
  }
}

.flow-contents {
  width: 1000px;
  margin: 40px auto 0;
}

@media (max-width: 767px) {
  .flow-contents {
    width: 100%;
  }
}

.flow-ttl-bg {
  background: #d1f1f7;
  height: 70px;
  position: relative;
  display: flex;
  align-items: center;
}

@media (max-width: 767px) {
  .flow-ttl-bg {
    height: 60px;
  }
}

.flow-ttl {
  display: inline-block;
  border-bottom: 1px solid #282828;
  margin-left: -1em;
  font-size: 2.4rem;
}

.flow-ttl span {
  font-size: 2.6rem;
}

@media (max-width: 767px) {
  .flow-ttl {
    font-size: 2rem;
    margin-left: -0.5em;
  }
}

@media (max-width: 767px) and (max-width: 767px) {
  .flow-ttl {
    font-size: 2rem;
  }
}

.flow-item._arr {
  position: relative;
  padding-bottom: 40px;
}

.flow-item._arr:before {
  position: absolute;
  bottom: 24px;
  left: 230px;
  content: "";
  width: 20px;
  height: 20px;
  border-bottom: 1px solid #282828;
  border-right: 1px solid #282828;
  transform: rotate(45deg);
  content: "";
}

@media (max-width: 767px) {
  .flow-item._arr:before {
    left: 50%;
    transform: rotate(45deg), translateX(-50%);
  }
}

.flow-box {
  padding: 24px 16px;
}

.flow-box-ttl {
  font-family: "ryumin-m";
  color: #0db7d6;
  font-size: 2rem;
}

.flow-box-txt {
  font-size: 1.4rem;
}

.flow-flex {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .flow-flex {
    flex-direction: column;
  }
}

.flow-txtitem,
.flow-img {
  width: 450px;
}

@media (max-width: 767px) {
  .flow-txtitem,
  .flow-img {
    width: 100%;
  }
}

/* **************************************


     merit


***************************************/
.merit {
  position: relative;
  padding: 100px 0;
  background-image: url("../images/merit/bg01.jpg");
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.merit::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
  background: rgba(40, 40, 40, 0.8);
  content: "";
}

@media (max-width: 767px) {
  .merit {
    padding: 60px 0;
  }
}

.merit-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 1000px;
  margin: auto;
  margin-top: 16px;
}

@media (max-width: 767px) {
  .merit-list {
    width: 100%;
    flex-direction: column;
  }
}

.merit-item {
  background: #fff;
  padding: 32px 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 340px;
  width: calc((100% / 3) - (40px / 3));
  margin-top: calc(40px / 3);
}

@media (max-width: 767px) {
  .merit-item {
    width: 280px;
    height: 280px;
    margin-top: 24px;
  }
}

.merit-item-img {
  margin-bottom: 24px;
  height: 120px;
}

@media (max-width: 767px) {
  .merit-item-img {
    height: 100px;
  }
  .merit-item-img img {
    max-width: 100%;
    max-height: 80px;
  }
}

.merit-item-txt {
  font-size: 2.4rem;
  margin-bottom: auto;
}

@media (max-width: 767px) {
  .merit-item-txt {
    font-size: 2rem;
  }
}

.merit-btn-contents {
  margin: 100px auto 0;
  text-align: center;
}

.merit-btn {
  display: inline-block;
  width: 300px;
  height: 75px;
  margin: auto;
  line-height: 75px;
  text-align: center;
  font-size: 2rem;
  transition: 0.2s;
  background: #fff;
}

.merit-btn:hover {
  background: #0db7d6;
}

.index-ttl {
  text-align: center;
  font-size: 3.2rem;
}

.index-ttl._merit, .index-ttl._adv {
  color: #fff;
}

.index-span {
  font-size: 1.4rem;
  text-align: center;
  width: 1000px;
  margin: auto;
  position: relative;
}

@media (max-width: 767px) {
  .index-span {
    width: 100%;
    font-size: 1.2rem;
  }
}

.index-span._merit {
  color: #fff;
}

.index-span._merit:before, .index-span._merit:after {
  width: calc(50% - 7em);
  background: #fff;
}

.index-span._flow:before, .index-span._flow:after {
  width: calc(50% - 6em);
}

.index-span._adv {
  color: #fff;
}

.index-span._adv:before, .index-span._adv:after {
  width: calc(50% - 9em);
  background: #fff;
}

.index-span._consul:before, .index-span._consul:after {
  width: calc(50% - 5em);
}

.index-span:before, .index-span:after {
  content: "";
  height: 1px;
  background: #282828;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.index-span:before {
  left: 0;
}

.index-span:after {
  right: 0;
}

.consul {
  padding: 100px 0 200px;
}

@media (max-width: 767px) {
  .consul {
    padding: 40px 0 120px;
  }
}

.consul-flex {
  width: 1000px;
  margin: 72px auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 767px) {
  .consul-flex {
    flex-direction: column;
    width: 100%;
  }
}

.consul-img._consul {
  width: 480px;
  display: block;
  position: relative;
}

.consul-img._consul:before {
  content: "";
  display: block;
  padding-top: 50%;
  /* 高さを幅の75%に固定 */
}

@media (max-width: 767px) {
  .consul-img._consul {
    width: 100%;
    max-width: 480px;
    margin-top: 24px;
  }
}

.consul-price {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 767px) {
  .consul-price {
    flex-direction: column;
  }
}

.price-img {
  height: 560px;
}

.price-img:before {
  content: "";
  background: rgba(13, 183, 214, 0.3);
  z-index: 1;
}

@media (max-width: 767px) {
  .price-img:before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
}

.price-img img {
  object-fit: cover;
  font-family: "object-fit:cover";
}

@media (max-width: 767px) {
  .price-img img {
    position: relative;
    left: auto;
    width: 100%;
  }
}

.price-img:before,
.price-img img {
  position: absolute;
  top: 0;
  left: -100px;
  height: 100%;
  width: 1070px;
}

@media (max-width: 767px) {
  .price-img {
    height: auto;
    width: 100%;
    position: relative;
    left: -24px;
    top: 50px;
  }
}

.price-box {
  background: #282828;
  width: 500px;
  height: 306px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  text-align: center;
}

@media (max-width: 767px) {
  .price-box {
    width: 100%;
    max-width: 500px;
    max-height: 300px;
    height: auto;
    padding: 40px 24px;
  }
}

.price-txt {
  font-size: 4rem;
}

.price-txt span {
  font-size: 2.8rem;
}

@media (max-width: 767px) {
  .price-txt {
    font-size: 3rem;
  }
  .price-txt span {
    font-size: 2rem;
  }
}
